import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import { useNavigate } from "react-router-dom";

export const ModalActualizaTusEskills = ({
  position,
  open,
  postActualizacionDatos,
}) => {

  const navigate = useNavigate();

  const handleActualizarDatos = () => {
    postActualizacionDatos();
    open(false);
    navigate("/miportal");
  };

  const headerContent = (
    <div>
      <p className="p1-modal-habilidades" style={{ fontWeight: "bold" }}>{`
   Actualización de datos `}</p>
    </div>
  );

  return (
    <Dialog
      draggable={false}
      closable={false}
      id="custom-bg-modal"
      style={{
        width: "384px",
        height: "168px",
      }}
      className="custom-modal"
      visible={true}
      position={position}
      header={headerContent}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginLeft: "20px",
          marginTop: "10px",
        }}
      >
        <p>
          ¡Hola! Mantener tus habilidades actualizadas es importante. ¿Tienes
          algo nuevo para agregar?
        </p>
      </div>
      <footer className="footer-modal-habilidades" style={{ border: "none" }}>
        <Button
          className="button-modal-habilidades-cancelar"
          onClick={handleActualizarDatos}
        >
          No
        </Button>
        <Button
          className="button-modal-habilidades"
          onClick={() => open(false)}
        >
          Sí
        </Button>
      </footer>
    </Dialog>
  );
};
