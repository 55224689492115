import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Tooltip } from "primereact/tooltip";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import ModalAceptada from "./ModalAceptada";
import ModalRechazada from "./ModalRechazada";
import { NovedadesContext } from "../contexts/NovedadesContext";
import { AuthContext } from "../contexts/AuthContext";
import PopUpResumen from "../components/PopUpResumen";

import {
  IDBodyTemplate,
  capitalizarPrimeraLetra,
  fechaHoraPopup,
  formatNumber,
} from "../helpers";
import * as XLSX from "xlsx";

const TableServiciosBDE = forwardRef(({ allChecked }, ref) => {
  const { loading, getNovedadesAYR, cambioNovedad, getNovedadesPendientes } =
    useContext(NovedadesContext);
  const { usuario } = useContext(AuthContext);
  const [estados] = useState(["Pendiente", "Aceptada", "Rechazada"]);
  const [novedades, setNovedades] = useState([]);
  const [novedadesFiltradas, setNovedadesFiltradas] = useState([]);
  const [visibleModalAceptar, setVisibleModalAceptar] = useState(false);
  const [visibleModalRechazar, setVisibleModalRechazar] = useState(false);
  const [novedadSeleccionada, setNovedadSeleccionada] = useState({});
  const [tipoDeNovedad, setTipoDeNovedad] = useState();
  const [resumenVisible, setResumenVisible] = useState(false);
  const [rowDataSelection, setRowDataSelection] = useState({});
  const dt = useRef(null);
  const [filteredData, setFilteredData] = useState([]);
  // const index = usuario.Email.indexOf("@");
  //  const user = usuario.Email.slice(0, index);

  const [filters, setFilters] = useState({
    IdSecuencial: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Fecha: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Usuario: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Periodo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Estado: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Resumen: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  });

  useEffect(() => {
    const handleGetNovedades = async () => {
      const data = await getNovedadesPendientes(
        usuario.Rol === "comercialSop" || usuario.Rol === "serviciosSop"
          ? "gerente|soporte"
          : "gerente"
      );
      const dataAceptada = await getNovedadesAYR(
        usuario.Rol === "comercialSop" || usuario.Rol === "serviciosSop"
          ? "gerente|soporte"
          : "gerente"
      );
      const dataRol = data.filter((e) => e.Tipo === "NP");
      const dataRolAceptada = dataAceptada.filter((e) => e.Tipo === "NP");

      setNovedades(dataRol.concat(dataRolAceptada));
      setNovedadesFiltradas(dataRol);

      setFilteredData(data.filter((e) => e.Estado === "pendiente"));
    };
    handleGetNovedades();
  }, [cambioNovedad]);

  useEffect(() => {
    setFilteredData(dt.current.props.value);
  }, [dt]);

  useEffect(() => {
    setFilteredData(allChecked ? novedades : novedadesFiltradas);
  }, [allChecked]);

  const handleResumenClick = (rowData) => {
    setTipoDeNovedad("Bandeja de Entrada");
    setResumenVisible(true);
    setRowDataSelection(rowData);
  };

  const limitarCaracteres = (value, maxLength) => {
    if (value.length > maxLength) {
      return value.substring(0, maxLength) + "...";
    }
    return value;
  };

  const resumenBodyTemplate = (rowData) => {
    return (
      <div className="campoResumen" onClick={() => handleResumenClick(rowData)}>
        <strong>Descripción: </strong>
        {rowData.Descripcion}
        <br />
        {/* {rowData.Reclamo ? "RECLAMO" : "PEDIDO"} */}
        {rowData.ImporteTotal !== 0 && (
          <>
            <strong>Importe Total: </strong>${" "}
            {formatNumber(rowData.ImporteTotal)}
            <br />
          </>
        )}
        {rowData.FechaDesde !== "" &&
          (rowData.FechaHasta !== "" ? (
            <>
              <strong>Fecha desde: </strong>
              {rowData.FechaDesde}
              <br />
              <strong>Fecha hasta: </strong>
              {rowData.FechaHasta}
              <br />
            </>
          ) : (
            <>
              <strong>Fecha: </strong>
              {rowData.FechaDesde}
              <br />
            </>
          ))}
        {rowData.Descripcion === "Informe y autorización horas extras" &&
          rowData.Recursos &&
          rowData.Recursos.length > 0 && (
            <div>
              {rowData.Recursos.map((e, index) => {
                if (index >= 0 && index <= 1 && e.HorasExtras) {
                  return (
                    <div key={index} style={{ margin: "3px 0 3px 6px" }}>
                      {e.HorasExtras?.map((p, i) => (
                        <div key={i}>
                          <b>Fecha: </b> {e.Periodo}
                          <div>
                            <i>Cantidad de Hs:</i> {p.Cantidad} <b>| </b>{" "}
                            <i>Tipo Dia:</i> {p.TipoDia} <b>| </b> {p.TipoHora}
                          </div>
                        </div>
                      ))}
                    </div>
                  );
                } else if (index === 2) {
                  return (
                    <div key={index}>
                      <b>
                        ...<i>Click para ver todas las horas extras</i>
                      </b>
                    </div>
                  );
                }
              })}
            </div>
          )}
        {rowData.Cantidad && rowData.Cantidad !== "0" && (
          <>
            <strong>Cantidad de Dias: </strong>
            {rowData.Cantidad}
            <br />
          </>
        )}
        {rowData.Adjuntos?.length > 0 && (
          <>
            <strong>Adjuntos: </strong>
            {rowData.Adjuntos}
            <br />
          </>
        )}
        {rowData.Motivo !== "" && (
          <>
            <strong>Motivo: </strong> {rowData.Motivo}
            <br />
          </>
        )}
        {rowData.Comentarios !== "" && (
          <>
            <strong>Comentarios: </strong>{" "}
            {limitarCaracteres(rowData.Comentarios, 30)}
            <br />
          </>
        )}
        {/* <>
          <strong>Prioridad: </strong> {rowData.Prioridad}
        </> */}
      </div>
    );
  };

  const estadoBodyTemplate = (rowData) => {
    return (
      <div>
        {rowData.Workflow[0].Estado === "pendiente" ? (
          <>
            <button
              onClick={() => {
                setVisibleModalAceptar(true);
                setNovedadSeleccionada(rowData);
              }}
              className="button-estado-modal green"
            >
              <i
                className="custom-target-icon pi pi-fw pi-check-circle"
                style={{ width: "60px" }}
                data-pr-tooltip="Aceptar"
              ></i>
            </button>
            <br />
            <br />
            <button
              onClick={() => {
                setVisibleModalRechazar(true);
                setNovedadSeleccionada(rowData);
              }}
              className="button-estado-modal red"
            >
              <i
                className="custom-target-icon pi pi-fw pi-times-circle"
                style={{ width: "60px" }}
                data-pr-tooltip="Rechazar"
              ></i>
            </button>
          </>
        ) : (
          capitalizarPrimeraLetra(rowData.Workflow[0].Estado)
        )}
      </div>
    );
  };

  const apenomBodyTemplate = (rowData) => {
    return <>{rowData?.UsuarioITP[0].Apellido+", "+rowData?.UsuarioITP[0].Nombre}</>;
  };

  const dateFilterTemplate = (options) => {
    return (
      <div>
        <div style={{ marginBottom: "5px", fontSize: "12px" }}>
          Fecha Exacta*
        </div>
        <InputMask
          value={options.value}
          onChange={(e) => options.filterApplyCallback(e.value)}
          mask="9999-99-99"
          // slotChar="yyyy-mm-dd"
          placeholder="Año-Mes-Dia"
        />
        <div style={{ marginBottom: "5px", fontSize: "12px" }}>Año-Mes*</div>
        <InputMask
          value={options.value}
          onChange={(e) => options.filterApplyCallback(e.value)}
          mask="9999-99"
          // slotChar="yyyy-mm-dd"
          placeholder="Año-Mes"
        />
      </div>
    );
  };

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={estados}
        onChange={(e) => options.filterApplyCallback(e.value)}
        // itemTemplate={statusItemTemplate}
        placeholder="Seleccione"
        className="p-column-filter"
        // showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };

  const exportCSV = () => {
    const allAccepted = filteredData.every(
      (product) => product.Estado === "aceptada"
    );
    const allRejected = filteredData.every(
      (product) => product.Estado === "rechazada"
    );

    const includeRecursos = filteredData.some(
      (product) => product.Recursos && product.Recursos.length > 0
    );

    const includeFechaDesde = filteredData.some(
      (product) => product.FechaDesde && product.FechaDesde.length > 0
    );
    const includeFechaHasta = filteredData.some(
      (product) => product.FechaHasta && product.FechaHasta.length > 0
    );
    const includeComentarios = filteredData.some(
      (product) => product.Comentarios
    );
    const includeAdjuntos = filteredData.some(
      (product) => product.Adjuntos && product.Adjuntos.length > 0
    );

    let headers = [
      "Tipo de novedad",
      "ID",
      "Usuario",
      "Fecha de carga",
      "Estado",
      "Cantidad de dias",
    ];
    if (includeRecursos)
      headers.push("Fecha", "Cantidad horas", "Tipo Dia", "Horario");
    if (includeFechaDesde) headers.push("Fecha desde");
    if (includeFechaHasta) headers.push("Fecha hasta");
    if (includeComentarios) headers.push("Comentarios");
    if (includeAdjuntos) headers.push("Adjuntos");

    if (allAccepted) {
      headers.push("Aprobado por", "Motivo");
    } else if (allRejected) {
      headers.push("Rechazado por", "Motivo");
    }
    const csvContent = [
      headers,
      ...filteredData.map((product) => {
        let row = [
          product.Descripcion || "",
          product.IdSecuencial || "",
          product.Usuario || "",
          product.Fecha || "",
          product.Estado || "",
          product.Cantidad || "",
        ];

        if (includeRecursos)
          row.push(
            product.Recursos.map((e) => e.Periodo).join(", ") || "",
            product.Recursos.map((e) =>
              e.HorasExtras.map((obj) => obj.Cantidad).join(", ")
            ).join("; ") || "",
            product.Recursos.map((e) =>
              e.HorasExtras.map((obj) => obj.TipoDia).join(", ")
            ).join("; ") || "",
            product.Recursos.map((e) =>
              e.HorasExtras.map((obj) => obj.TipoHora).join(", ")
            ).join("; ") || ""
          );

        if (includeFechaDesde) row.push(product.FechaDesde || "");
        if (includeFechaHasta) row.push(product.FechaHasta || "");

        if (includeComentarios) row.push(product.Comentarios || "");
        if (includeAdjuntos) row.push(product.Adjuntos?.join(", ") || "");
        if (allAccepted || allRejected) {
          row.push(
            product.Workflow[0].Autorizador +
              "(" +
              product.Workflow[0].Aprobador +
              ")" +
              fechaHoraPopup(product.Workflow[0].Fecha).fechaHora || "",
            product.Motivo || ""
          );
        }
        return row;
      }),
    ];
    const ws = XLSX.utils.aoa_to_sheet(csvContent);
    ws["!cols"] = [
      { wpx: 100 },
      { wpx: 50 },
      { wpx: 120 },
      { wpx: 80 },
      { wpx: 50 },
      { wpx: 50 },
      { wpx: 50 },
      { wpx: 200 },
      { wpx: 80 },
      { wpx: 30 },
      { wpx: 250 },
      { wpx: 200 },
      { wpx: 200 },
    ];

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Hoja1");

    // Exportar el archivo Excel
    XLSX.writeFile(wb, "Novedades.xlsx");
  };

  useImperativeHandle(ref, () => ({
    ejecutarFuncion: exportCSV,
  }));

  return (
    <>
      <ModalAceptada
        visible={visibleModalAceptar}
        setVisible={setVisibleModalAceptar}
        novedadSeleccionada={novedadSeleccionada}
      />
      <ModalRechazada
        visible={visibleModalRechazar}
        setVisible={setVisibleModalRechazar}
        novedadSeleccionada={novedadSeleccionada}
      />
      <Tooltip target=".custom-target-icon" />
      <DataTable
        onValueChange={(data) => setFilteredData(data)}
        ref={dt}
        value={allChecked ? novedades : novedadesFiltradas}
        scrollable
        paginator
        className="p-datatable-customers"
        rows={10}
        dataKey="IdSecuencial"
        filters={filters}
        filterDisplay="menu"
        loading={loading}
        responsiveLayout="scroll"
        emptyMessage="Sin resultados."
        style={{ fontFamily: "var(--main-font)" }}
        sortField="IdSecuencial"
        sortMode="single" //sortMode="multiple"
        sortOrder={-1}
      >
        <Column
          sortable
          field="IdSecuencial"
          header="ID"
          style={{ justifyContent: "center", minWidth: "2rem" }}
          filter
          filterPlaceholder="Buscar por ID"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
          body={IDBodyTemplate}
        />
        <Column
          sortable
          field="Fecha"
          header="Fecha"
          style={{ minWidth: "8rem" }}
          filter
          filterElement={dateFilterTemplate}
          filterPlaceholder="Buscar por fecha"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
        />
        <Column
          sortable
          field="ApeNom"
          header="Usuario"
          style={{ minWidth: "15rem" }}
          filter
          filterPlaceholder="Buscar por Apellido"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
          body={apenomBodyTemplate}
        />
        <Column
          sortable
          field="Usuario"
          header="Email"
          style={{ minWidth: "10rem" }}
          filter
          filterPlaceholder="Buscar por Usuario"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
        />
        <Column
          sortable
          field="Periodo"
          header="Periodo"
          style={{ minWidth: "8rem" }}
          filter
          filterPlaceholder="Buscar por Periodo"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
        />
        <Column
          sortable
          field="Estado"
          header="Estado"
          style={{ minWidth: "10rem" }}
          filter
          filterPlaceholder="Buscar por estado"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
          body={estadoBodyTemplate}
          filterElement={statusFilterTemplate}
        />
        <Column
          field="Resumen"
          header="Resumen"
          style={{
            justifyContent: "start",
            minWidth: "400px",
            whiteSpace: "pre-line",
            textAlign: "start",
          }}
          body={resumenBodyTemplate}
          filter
          filterPlaceholder="Buscar"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
        />
      </DataTable>
      <PopUpResumen
        resumenVisible={resumenVisible}
        setResumenVisible={setResumenVisible}
        rowDataSelection={rowDataSelection}
        tipoDeNovedad={tipoDeNovedad}
        setTipoDeNovedad={setTipoDeNovedad}
      />
    </>
  );
});

export default TableServiciosBDE;
