import { useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import PopUpRecursos from "../../components/PopUpRecursos";

const CecosTable = ({ dataValues }) => {
  const [visible, setVisible] = useState(false);
  const [idCeco, setIdCeco] = useState(0);

  const onClickGetRecursos = (rowData) => {
    setVisible(true);
    setIdCeco(rowData.Codigo);
  };

  return (
    <>
      <DataTable
        value={dataValues}
        scrollable
        paginator
        className="p-datatable-customers"
        rows={12}
        dataKey="IdSecuencial"
        filterDisplay="menu"
        responsiveLayout="scroll"
        emptyMessage="Sin resultados."
        style={{ fontFamily: "var(--main-font)" }}
        sortField="IdSecuencial"
        sortMode="single"
        sortOrder={-1}
      >
        <Column
          sortable
          field="Codigo"
          header="Codigo"
          style={{ 
            minWidth: "2rem",
            maxWidth: "14rem"
          }}
          filter
          filterPlaceholder="Buscar por codigo"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
        />
        <Column
          sortable
          field="Cliente"
          header="Cliente"
          style={{
            minWidth: "8rem",
            maxWidth: "20rem",
            textAlign: "start",
            justifyContent: "start",
          }}
          filter
          filterPlaceholder="Buscar por cliente"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
        />
        <Column
          sortable
          field="Proyecto"
          header="Proyecto"
          style={{
            minWidth: "8rem",
            maxWidth: "20rem",
            textAlign: "start",
            justifyContent: "start",
          }}
          filter
          filterPlaceholder="Buscar por proyecto"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
        />
        <Column
          sortable
          field="CuitCuil"
          header="CUIT/CUIL"
          style={{
            minWidth: "8rem",
            maxWidth: "10rem",
            textAlign: "start",
            justifyContent: "start",
          }}
          filter
          filterPlaceholder="Buscar por cuit/cuil"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
        />
        <Column
          style={{ minWidth: "4rem", maxWidth: "10rem" }}
          header="Recursos"
          bodyStyle={{ textAlign: "center" }}
          body={(rowData) => (
            <i
              onClick={() => onClickGetRecursos(rowData)}
              style={{
                color: "#084bfa",
                cursor: "pointer",
              }}
              className="pi pi-eye cc-recursos-column"
            />
          )}
        />
      </DataTable>
      <PopUpRecursos
        visible={visible}
        setVisible={setVisible}
        idCeco={idCeco}
      />
    </>
  );
};

export default CecosTable;