export const tipoAreas = Object.freeze([
  { type: "PO", data: "People Operations" },
  { type: "TA", data: "Talent Acquisition" }, // todas las TA SACARLAS
  { type: "CULTURA", data: "Cultura" },
  { type: "SOPORTE", data: "Soporte" },
]);

export const GRUPO_SOLICITUDES_TYPES = Object.freeze({
  LIC: "LIC",
  SUEL: "SUEL",
  GRAL: "GRAL",
});

export const tipoSolicitudes = Object.freeze([
  /*   {
    type: "SOAS",
    area: "SOPORTE",
    grupo: GRUPO_SOLICITUDES_TYPES.GRAL,
    data: "Asistencia software PC",
  }, */
  {
    type: "LIVA",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.LIC,
    data: "Licencia por vacaciones",
  },
  {
    type: "LIEX",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.LIC,
    data: "Patagonian education",
  },
  {
    type: "LIEC",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.LIC,
    data: "Licencia por excedencia",
  },
  {
    type: "LIMT",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.LIC,
    data: "Licencia por maternidad",
  },
  {
    type: "LIMR",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.LIC,
    data: "Licencia por matrimonio",
  },
  {
    type: "OTLC",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.LIC,
    data: "Otras licencias",
  },
  {
    type: "LISG",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.LIC,
    data: "Licencia sin goce de sueldo",
  },
  {
    type: "LICG",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.LIC,
    data: "Licencia con goce de sueldo",
  },
  {
    type: "LIAD",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.LIC,
    data: "Licencia por adopción",
  },
  {
    type: "LIEN",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.LIC,
    data: "Licencia extendida NEO",
  },
  {
    type: "LIPG",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.LIC,
    data: "Licencia por pérdida gestacional",
  },
  {
    type: "LICO",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.LIC,
    data: "Licencia por congelamiento de óvulos",
  },
  {
    type: "LIPT",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.LIC,
    data: "Licencia por paternidad",
  },
  {
    type: "LICH",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.LIC,
    data: "1/2 off por cumpleaños hijo (en edad escolar)",
  },
  {
    type: "LIPD",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.LIC,
    data: "Patagonian day",
  },
  {
    type: "LIEN",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.LIC,
    data: "Licencia por enfermedad",
  },
  {
    type: "LIEF",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.LIC,
    data: "Escritorio flex",
  },
  {
    type: "LICQ",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.LIC,
    data: "Chequeate",
  },
  {
    type: "LIPB",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.LIC,
    data: "Patagonian birthday",
  },
  {
    type: "LIGR",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.LIC,
    data: "Patagonian graduation",
  },

  {
    type: "RHAS",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.SUEL,
    data: "Adelanto de sueldos",
  },
  {
    type: "RHPS",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.SUEL,
    data: "Préstamo de sueldo",
  },

  {
    type: "RHME",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.GRAL,
    data: "Consultas/solicitudes medicina",
  },
  {
    type: "RHCD",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.GRAL,
    data: "Notificación de cambio de domicilio",
  },
  {
    type: "RHHE",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.GRAL,
    data: "Informe y autorización horas extras",
  },
  {
    type: "RHCB",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.GRAL,
    data: "Cuentas bancarias",
  },
  {
    type: "RHLS",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.GRAL,
    data: "Consultas liquidación de sueldos",
  },
  {
    type: "RHEL",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.GRAL,
    data: "Error en liquidación de sueldos",
  },
  {
    type: "LIPF",
    area: "PO",
    grupo: GRUPO_SOLICITUDES_TYPES.LIC,
    data: "Licencia por fallecimiento",
  },
  

  /*
   */
  /*  {
    type: "SEBC",
    area: "TA",
    grupo: GRUPO_SOLICITUDES_TYPES.GRAL,
    data: "Solicitud búsqueda contractor",
  },
  {
    type: "SEBH",
    area: "TA",
    grupo: GRUPO_SOLICITUDES_TYPES.GRAL,
    data: "Solicitud búsqueda hiring",
  },
  {
    type: "SEPR",
    area: "TA",
    grupo: GRUPO_SOLICITUDES_TYPES.GRAL,
    data: "Plan referidos",
  } */
  {
    type: "SOAS",
    area: "SOPORTE",
    grupo: GRUPO_SOLICITUDES_TYPES.GRAL,
    data: "Asistencia software PC",
  },
  {
    type: "SOAH",
    area: "SOPORTE",
    grupo: GRUPO_SOLICITUDES_TYPES.GRAL,
    data: "Asistencia hardware PC",
  },
  {
    type: "SOPA",
    area: "SOPORTE",
    grupo: GRUPO_SOLICITUDES_TYPES.GRAL,
    data: "Permisos de acceso",
  },
  {
    type: "SOSB",
    area: "SOPORTE",
    grupo: GRUPO_SOLICITUDES_TYPES.GRAL,
    data: "Servidores y backups",
  },
  {
    type: "CUAB",
    area: "CULTURA",
    grupo: GRUPO_SOLICITUDES_TYPES.GRAL,
    data: "Alta beneficios",
  },
]);

export const tipoPrioridades = [
  { data: "Baja" },
  { data: "Media" },
  { data: "Alta" },
];

export const aprobadores = [
  { data: "jlanzani@itpatagonia.com" },
  { data: "cbianco@itpatagonia.com" },
  { data: "aturne@itpatagonia.com" },
  { data: "phperez@itpatagonia.com" },
  { data: "mbianco@itpatagonia.com" },
];
