import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterOperator, FilterMatchMode } from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import { useEffect, useState, useContext } from "react";
import { dataFreelance } from "./freelance.mock";

import FormFreelance from "./FormFreelance";
import ModalHistorial from "./ModalHistorial";
import { Dropdown } from "primereact/dropdown";
import { NovedadesContext } from "../../contexts/NovedadesContext";

const TableFreelance = ({ data, loading }) => {
  const [allUsersFreelance, setAllUsersFreelance] = useState([]);
  const [displayUserModal, setDisplayUserModal] = useState(false);
  const [displayHistorialModal, setDisplayHistorialModal] = useState(false);
  const [datosRegistro, setDatosRegistro] = useState(0);
  const [verSoloLectura, setVerSoloLectura] = useState(false);
  const [freelanceHistorial, setFreelanceHistorial] = useState([]);
  const { getHistorialFreelance, cambioNovedad, recursosArray } =
    useContext(NovedadesContext);
  const nomina = [
    { label: "Si", value: "S" },
    { label: "No", value: "N" },
  ];

  const filters = {
    CUIT: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Apellido: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Nombre: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Gerente: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Nomina: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    Habilitado: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  };

  useEffect(() => {
    const handleGetHistorial = async () => {
      const data = await getHistorialFreelance();
      setFreelanceHistorial(data);
    };
    setAllUsersFreelance(dataFreelance);
    handleGetHistorial();
  }, [displayHistorialModal]);

  const nominaFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={nomina}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Seleccione"
        className="p-column-filter"
        showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };

  const estadoGerenteTemplate = (rowData) => {
    const legajoGerente = recursosArray.filter(
      (x) => x.Legajo === rowData?.Gerente
    );
    const dataGerente = legajoGerente[0]?.datoGerente;
    return <div>{dataGerente}</div>;
  };

  return (
    <div>
      <Tooltip target=".custom-target-icon" />
      <DataTable
        value={data}
        scrollable
        paginator
        loading={loading}
        className="p-datatable-customers"
        rows={15}
        dataKey="nroFreelance"
        filters={filters}
        filterDisplay="menu"
        emptyMessage="Sin resultados."
        style={{ fontFamily: "var(--main-font)" }}
      >
        {/* <Column
          sortable
          field="NroFreelance"
          header="Numero"
          style={{ minWidth: "16rem", maxWidth: "44rem" }}
          filter
          filterField="cuit"
          filterPlaceholder="Buscar por CUIT"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
        /> */}
        <Column
          sortable
          field="CUIT"
          header="CUIT"
          style={{ maxWidth: "8rem" }}
          filter
          filterField="CUIT"
          filterPlaceholder="Buscar por CUIT"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
        />
        <Column
          sortable
          field="Apellido"
          header="Apellido"
          style={{
            minWidth: "12rem",
            justifyContent: "start",
          }}
          filter
          filterField="Apellido"
          filterPlaceholder="Buscar por apellido"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
        />
        <Column
          sortable
          field="Nombre"
          header="Nombre"
          style={{
            minWidth: "12rem",
            justifyContent: "start",
          }}
          filter
          filterField="Nombre"
          filterPlaceholder="Buscar por nombre"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
        />
        <Column
          sortable
          field="Gerente"
          header="Gerente"
          style={{ minWidth: "12rem", justifyContent: "start" }}
          filter
          filterPlaceholder="Buscar por nro. de legajo"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
          body={estadoGerenteTemplate}
        />
        <Column
          sortable
          field="Nomina"
          header="Nomina"
          style={{ minWidth: "6rem", maxWidth: "9rem" }}
          filter
          filterPlaceholder="Buscar por nomina"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
          filterElement={nominaFilterTemplate}
        />
        <Column
          sortable
          field="Habilitado"
          header="Habilitado"
          style={{ minWidth: "10rem", maxWidth: "9rem" }}
          filter
          filterPlaceholder="Buscar por habilitado"
          showAddButton={false}
          showFilterOperator={false}
          showFilterMatchModes={false}
          filterElement={nominaFilterTemplate}
        />
        <Column
          style={{ minWidth: "6rem", maxWidth: "15rem" }}
          header="Acciones"
          bodyStyle={{ textAlign: "center" }}
          body={(rowData) => (
            <>
              <i
                className="pi pi-eye custom-target-icon"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setDatosRegistro(rowData);
                  setVerSoloLectura(true);
                  setDisplayUserModal(true);
                }}
                data-pr-tooltip="Ver"
                data-pr-position="top"
              ></i>
              <i
                className="pi pi-pencil custom-target-icon"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setDatosRegistro(rowData);
                  setVerSoloLectura(false);
                  setDisplayUserModal(true);
                }}
                data-pr-tooltip="Editar"
                data-pr-position="top"
              ></i>
              <i
                className="pi pi-book custom-target-icon"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setDatosRegistro(rowData);
                  setDisplayHistorialModal(true);
                }}
                data-pr-tooltip="Logs"
                data-pr-position="top"
              ></i>
            </>
          )}
        />
      </DataTable>
      <FormFreelance
        setDisplayModal={setDisplayUserModal}
        displayModal={displayUserModal}
        datosRegistro={datosRegistro}
        verSoloLectura={verSoloLectura}
      />
      <ModalHistorial
        setDisplayModal={setDisplayHistorialModal}
        displayModal={displayHistorialModal}
        dataFreelance={datosRegistro}
        freelanceHistorial={freelanceHistorial}
        cambioNovedad={cambioNovedad}
      />
    </div>
  );
};

export default TableFreelance;
