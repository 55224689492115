export const holidaysMock2024 = [
  {
    title: "Año nuevo",
    start: new Date("2025-01-01 00:00"),
    end: new Date("2025-01-01 23:59"),
  },
  {
    title: "Carnaval",
    start: new Date("2025-03-03 00:00"),
    end: new Date("2025-03-03 23:59"),
  },
  {
    title: "Carnaval",
    start: new Date("2025-03-04 00:00"),
    end: new Date("2025-03-04 23:59"),
  },
  {
    title: "Día Nacional de la Memoria por la Verdad y la Justicia",
    start: new Date("2025-03-24 00:00"),
    end: new Date("2025-03-24 23:59"),
  },
  {
    title: "Día del Veterano y de los Caídos en la Guerra de Malvinas",
    start: new Date("2025-04-02 00:00"),
    end: new Date("2025-04-02 23:59"),
  },
  {
    title: "Viernes Santo",
    start: new Date("2025-04-18 00:00"),
    end: new Date("2025-04-18 23:59"),
  },
  {
    title: "Día del Trabajador",
    start: new Date("2025-05-01 00:00"),
    end: new Date("2025-05-01 23:59"),
  },
  {
    title: "Puente turístico no laborable",
    start: new Date("2025-05-02 00:00"),
    end: new Date("2025-05-02 23:59"),
  },
  {
    title: "Día de la Revolución de Mayo",
    start: new Date("2025-05-25 00:00"),
    end: new Date("2025-05-25 23:59"),
  },
  {
    title: "Paso a la Inmortalidad del General Martín Güemes",
    start: new Date("2025-06-16 00:00"),
    end: new Date("2025-06-16 23:59"),
  },
  {
    title: "Paso a la Inmortalidad del General Manuel Belgrano",
    start: new Date("2025-06-20 00:00"),
    end: new Date("2025-06-20 23:59"),
  },
  {
    title: "Día de la Independencia",
    start: new Date("2025-07-09 00:00"),
    end: new Date("2025-07-09 23:59"),
  },
  {
    title: "Puente turístico no laborable",
    start: new Date("2025-08-15 00:00"),
    end: new Date("2025-08-15 23:59"),
  },
  {
    title: "Paso a la Inmortalidad del Gral. José de San Martín",
    start: new Date("2025-08-17 00:00"),
    end: new Date("2025-08-17 23:59"),
  },
  {
    title: "Día del Respeto a la Diversidad Cultural",
    start: new Date("2025-10-12 00:00"),
    end: new Date("2025-10-12 23:59"),
  },
  {
    title: "Puente turístico no laborable",
    start: new Date("2025-11-21 00:00"),
    end: new Date("2025-11-21 23:59"),
  },
  {
    title: "Día de la Soberanía Nacional",
    start: new Date("2025-11-24 00:00"),
    end: new Date("2025-11-24 23:59"),
  },
  {
    title: "Día de la Inmaculada Concepción de María",
    start: new Date("2025-12-08 00:00"),
    end: new Date("2025-12-08 23:59"),
  },
  {
    title: "Navidad",
    start: new Date("2025-12-25 00:00"),
    end: new Date("2025-12-25 23:59"),
  },
];
