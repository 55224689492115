import { Card } from "primereact/card";
import React from "react";
import "./Habilidades.css";
import { Tooltip } from "primereact/tooltip";

export const CardHabilidades = ({
  icon,
  title,
  description,
  info,
  cancel,
  setInfoModal,
  data,
  setEditSkill,
  open,
  setDatos,
}) => {
  const uniqueId = `info-icon-${title.replace(/\s+/g, "-").toLowerCase()}`;

  return (
    <>
      <Tooltip target={`.${uniqueId}`} content={info} position="top" />
      <Card
        style={{
          width: "45%",
          height: "235px",
          marginTop: "20px",
          position: "relative",
        }}
        className="custom-card-habilidades"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "1.25rem 1.25rem 0 1.25rem",
          }}
        >
          <div className="div-1-habilidades">
            <>
              <span
                className="material-symbols-outlined"
                style={{ fontSize: "25px" }}
              >
                {icon}
              </span>
              <p className="p1-habilidades" style={{ paddingLeft: "15px" }}>
                {title}
              </p>
            </>
          </div>
          <div>
            <>
              {title === "Carga de cv" && data.length > 0 ? (
                ""
              ) : (
                <span
                  className="material-symbols-outlined"
                  style={{ cursor: "pointer", fontSize: "25px" }}
                  onClick={() => {
                    cancel(true);
                    setInfoModal(title);
                    setDatos(data);
                    setEditSkill(false);
                  }}
                >
                  add
                </span>
              )}
              {data.length > 0 && (
                <span
                  style={{
                    paddingLeft: "10px",
                    cursor: "pointer",
                    fontSize: "25px",
                  }}
                  className="material-symbols-outlined"
                  onClick={() => {
                    title === "Skills Complementarias" && cancel(true);
                    setEditSkill(true);
                    setInfoModal(title);
                    setDatos(data) ||
                      ((title === "Certificaciones" ||
                        title === "Nivel de estudios" ||
                        title === "Tecnologías" ||
                        title === "Carga de cv") &&
                        open(true));
                    setInfoModal(title);
                    setDatos(data);
                  }}
                >
                  edit
                </span>
              )}
              <span
                style={{
                  paddingLeft: "10px",
                  cursor: "pointer",
                  fontSize: "25px",
                }}
                className={`material-symbols-outlined ${uniqueId}`}
              >
                Help
              </span>
            </>
          </div>
        </div>
        {title === "Skills Complementarias" &&
          (data.length > 0 ? (
            <>
              <div className="body-card-habilidades">
                {data.slice(0, 4).map((item, index) => (
                  <p
                    key={item._id}
                    className={`p-body-card-habilidades ${
                      index > 0 ? "p-body-card-habilidades-border" : ""
                    }`}
                  >
                    {item.name}
                  </p>
                ))}
              </div>
              {data.length > 4 && (
                <footer
                  className="footer-card-habilidades"
                  onClick={() => {
                    open(true);
                    setInfoModal(title);
                    setDatos(data);
                  }}
                >
                  {`Mostrar todas las
                ${title === "Skills Complementarias" ? "Skills" : title} (${
                    data.length
                  })`}
                </footer>
              )}
            </>
          ) : (
            <p className="p2-habilidades" style={{ marginTop: "10px" }}>
              {description}
            </p>
          ))}

        {title === "Certificaciones" &&
          (data.length > 0 ? (
            <>
              {data.slice(0, 3).map((item, index) => (
                <div className="body-card-habilidades-certi" key={item._id}>
                  <p
                    className={`p-body-card-habilidades-certi ${
                      index > 0 ? "p-body-card-habilidades-border" : ""
                    }`}
                  >
                    {item.certificationTitle}
                  </p>
                  <p className={``}>{item.institutionName}</p>
                </div>
              ))}
              {data.length > 2 && (
                <footer
                  className="footer-card-habilidades"
                  onClick={() => {
                    open(true);
                    setInfoModal(title);
                    setDatos(data);
                  }}
                >
                  {`Mostrar todas las
                ${title === "Skills Complementarias" ? "Skills" : title} (${
                    data.length
                  })`}
                </footer>
              )}
            </>
          ) : (
            <p className="p2-habilidades" style={{ marginTop: "10px" }}>
              {description}
            </p>
          ))}

        {title === "Tecnologías" &&
          (data.length > 0 ? (
            <>
              <div className="body-card-habilidades">
                {data.slice(0, 4).map((item, index) => (
                  <p
                    key={item._id}
                    className={`p-body-card-habilidades ${
                      index > 0 ? "p-body-card-habilidades-border" : ""
                    }`}
                  >
                    {item.subtechnologyName
                      ? `${item.technologyName} / ${item.subtechnologyName}`
                      : item.technologyName}
                  </p>
                ))}
              </div>

              {data.length > 4 && (
                <footer
                  className="footer-card-habilidades"
                  onClick={() => {
                    open(true);
                    setInfoModal(title);
                    setDatos(data);
                  }}
                >
                  {`Mostrar todas las
                ${title === "Skills Complementarias" ? "Skills" : title} (${
                    data.length
                  })`}
                </footer>
              )}
            </>
          ) : (
            <p className="p2-habilidades" style={{ marginTop: "10px" }}>
              {description}
            </p>
          ))}

        {title === "Nivel de estudios" &&
          (data.length > 0 ? (
            <>
              {data.slice(0, 3).map((item, index) => (
                <div className="body-card-habilidades-certi" key={item._id}>
                  <p
                    className={`p-body-card-habilidades-certi ${
                      index > 0 ? "p-body-card-habilidades-border" : ""
                    }`}
                  >
                    {item.degree}
                  </p>
                  <p className={``}>{item.institution}</p>
                </div>
              ))}
              {data.length > 3 && (
                <footer
                  className="footer-card-habilidades"
                  onClick={() => {
                    open(true);
                    setInfoModal(title);
                    setDatos(data);
                  }}
                >
                  {`Mostrar todos los
                ${title === "Skills Complementarias" ? "Skills" : title} (${
                    data.length
                  })`}
                </footer>
              )}
            </>
          ) : (
            <p className="p2-habilidades" style={{ marginTop: "10px" }}>
              {description}
            </p>
          ))}

        {title === "Carga de cv" &&
          (data.length > 0 ? (
            <>
              {data.slice(0, 3).map((item, index) => (
                <div className="body-card-habilidades-certi" key={item._id}>
                  <p
                    className={`p-body-card-habilidades-certi ${
                      index > 0 ? "p-body-card-habilidades-border" : ""
                    }`}
                  >
                    {item.fileName}
                  </p>
                  <p className={``}>{item.institutionName}</p>
                </div>
              ))}
              {data.length > 2 && (
                <footer
                  className="footer-card-habilidades"
                  onClick={() => {
                    open(true);
                    setInfoModal(title);
                    setDatos(data);
                  }}
                >
                  {`Mostrar todas las
                ${title === "Skills Complementarias" ? "Skills" : title} (${
                    data.length
                  })`}
                </footer>
              )}
            </>
          ) : (
            <p className="p2-habilidades" style={{ marginTop: "10px" }}>
              {description}
            </p>
          ))}
      </Card>
    </>
  );
};
